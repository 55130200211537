export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST'
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS'
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE'
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS'
export const SEE_DETAIL = 'SEE_DETAIL'
export const CHANGE_QTY = 'CHANGE_QTY'
export const SELECT_SORT_DEFAULT = 'SELECT_SORT_DEFAULT'
export const SELECT_SORT_BY_VALUE_INC = 'SELECT_SORT_BY_VALUE_INC'
export const SELECT_SORT_BY_VALUE_DEC = 'SELECT_SORT_BY_VALUE_DEC'
export const SET_CATEGORIES = 'SET_CATEGORIES'